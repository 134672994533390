import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
    API_RESOURCE_LOCATION,
    API_RESOURCE_PRODUCT,
    API_RESOURCE_OFFER,
    API_RESOURCE_USER,
} from 'Consts/apiResources';

import { getFullName } from 'Utils/user';
import { getActivePromoCodeInLocation } from 'Utils/promoCode';

import Spinner from 'Components/layout/Spinner';
import Head from 'Components/core/Head';
import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import TrainingSelectForm from 'Components/public/reservations/SlotSelectForm';
import CheckoutForm from 'Components/public/reservations/CheckoutForm';

export default class PublicReservationsOnline extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            getLocationBySlug: PropTypes.func.isRequired,
            singleOfferBySlug: PropTypes.func.isRequired,
            singleProduct: PropTypes.func.isRequired,
            singleShopBySlug: PropTypes.func.isRequired,
        }).isRequired,
    };

    static defaultProps = {};

    state = {
        selectedLocation: null,
        selectedOffer: null,
        selectedProduct: null,
        selectedSlot: null,
        formState: null,
        trainer: null,
        availableProducts: [{
            id: process.env.APP_ENV === 'development' || process.env.APP_ENV === 'staging' 
                ? '6609fa6b-7ab3-499c-a9ba-2abf8f6b8e06' 
                : '5ab65566-35a6-4858-8c70-f81793b47e7e',
            slug: 'offline',
            location: 'sobieski',
            name: 'Trening w studiu treningowym',
            feature: 'Treningi personalne w zamkniętym studiu treningowym FitAdept w Warszawie',
            isVisible: true,
        }, {
            id: process.env.APP_ENV === 'development' || process.env.APP_ENV === 'staging' 
                ? '91538a88-78a0-45e0-ad38-113a9c0b8c4e' 
                : '30defbc6-0b99-4954-a187-b1eab3eecc90',
            slug: 'online',
            location: 'online',
            name: 'Trening online',
            feature: 'Wideo-treningi personalne online (1:1 z certyfikowanym trenerem)',
            isVisible: true,
        }],
        availableOffers: [{
            type: 'online',
            slug: 'diagnostyczna-online-rezerwacje',
        }, {
            type: 'offline',
            slug: 'diagnostyczna-rezerwacje',
        }],
        isLoading: false,
        isPageLoading: true,
        fallBackPageView: false,
        shopPage: {},
    }

    componentDidMount = () => {
        this.loadTrainer();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { shopPage, selectedLocation } = this.state;

        if (shopPage && selectedLocation && JSON.stringify(shopPage) !== JSON.stringify(prevState.shopPage)) {
            this.loadShop(selectedLocation.slug);
        }
    }

    loadProduct = (id) => {
        const { actions } = this.props;
        
        this.setState({
            isLoading: true,
        });

        actions.singleProduct({ id })
            .then(response => {
                this.setState({ 
                    selectedProduct: response.payload[API_RESOURCE_PRODUCT],
                    isLoading: false,
                });
            });
    }

    loadTrainer = () => {
        const { actions, match } = this.props;
        const { availableProducts, availableOffers } = this.state; 

        let slug = match.params.slug;

        return actions.getUserBySlug({ slug })
            .then(response => {
                const result = response.payload[API_RESOURCE_USER]; 

                const isOnlineSpecializationAvailable = Boolean(result.userSpecializations.find(userSpecialization => userSpecialization?.specialization?.slug === 'online' && userSpecialization?.specialization?.enabled));
                const isOfflineSpecializationAvailable = Boolean(result.userSpecializations.find(userSpecialization => userSpecialization?.specialization?.slug === 'offline' && userSpecialization?.specialization?.enabled));

                if (isOnlineSpecializationAvailable) {
                    const productId = availableProducts.find(product => product.slug === 'online')?.id;
                    const locationSlug = availableProducts.find(product => product.slug === 'online')?.location;  
                    const offerSlug = availableOffers.find(offer => offer.type === 'online')?.slug;
  
                    this.loadProduct(productId);
                    this.loadOffer(offerSlug);
                    this.loadLocation(locationSlug);
                    this.loadShop(locationSlug);
                } else if (isOfflineSpecializationAvailable) {
                    const productId = availableProducts.find(product => product.slug === 'offline')?.id;
                    const locationSlug = availableProducts.find(product => product.slug === 'offline')?.location;  
                    const offerSlug = availableOffers.find(offer => offer.type === 'offline')?.slug;
  
                    this.loadProduct(productId);
                    this.loadOffer(offerSlug);
                    this.loadLocation(locationSlug);
                    this.loadShop(locationSlug);
                }

                if (!isOnlineSpecializationAvailable && !isOfflineSpecializationAvailable) {
                    this.setState({
                        isPageLoading: false,
                        fallBackPageView: true,
                    });
                }

                this.setState({ 
                    trainer: result,
                    isOfflineSpecializationAvailable,
                    isOnlineSpecializationAvailable,
                    isPageLoading: false,
                }, this.setAvailableProducts);
            })
            .catch(error => {
                this.setState({
                    isPageLoading: false,
                    fallBackPageView: true,
                });
            });
    }
  
    setAvailableProducts = () => {
        const { isOfflineSpecializationAvailable, isOnlineSpecializationAvailable, availableProducts } = this.state;

        if (!isOfflineSpecializationAvailable || !isOnlineSpecializationAvailable) {
            const updatedAvailablePrducts = availableProducts.map(product => ({ ...product, isVisible: false }));

            this.setState({ availableProducts: updatedAvailablePrducts });
        } 
    }

    loadOffer = (slug) => {
        const { actions } = this.props;

        actions.singleOfferBySlug({ slug })
            .then(response => {
                this.setState({ selectedOffer: response.payload[API_RESOURCE_OFFER] });
            });
    }

    loadLocation = (slug) => {
        const { actions } = this.props;

        actions.getLocationBySlug({ slug })
            .then(response => {
                const location = response.payload[API_RESOURCE_LOCATION];
                const discountCode = getActivePromoCodeInLocation(location);

                this.setState(prevState => ({
                    ...prevState,
                    selectedLocation: location,
                    formState: {
                        ...prevState.formState,
                        comment: discountCode,
                    },
                }));
            });
    }

    loadShop = (locationSlug) => {
        const { actions } = this.props;

        actions.singleShopBySlug({
            slug: locationSlug
        }).then(response => {
            const shopPage = response.payload['shopPage'];
            this.setState({
                shopPage
            })
        });
    }

    onTrainingSelectFormSubmit = data => {
        this.setState({ selectedSlot: data });
    }

    onResetTraining = () => {
        this.setState({
            selectedSlot: null,
        });
    }

    onFormStateChange = (formState) => {
        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...formState,
            },
        }));
    }

    render() {
        const { location, history } = this.props;
        const { 
            selectedOffer,
            selectedProduct,
            selectedLocation,
            selectedSlot,
            formState,
            trainer,
            availableProducts,
            isPageLoading,
            fallBackPageView,
            shopPage,
        } = this.state;

        const isPageLoaded = selectedOffer && selectedProduct && selectedLocation && shopPage;

        return (
            <StyledComponent
                className={classnames({
                    'component-reservations': true,
                })}
                styles={require('./styles')}
            >
                {isPageLoading && (
                    <Spinner />
                )}
                <Head
                    title="Rezerwacje treningów"
                    description="Rezerwacje treningów FitAdept"
                />
                {!isPageLoading && fallBackPageView && (
                    <div className="content fallback">
                        <div className="column">
                            <h1 className="headline">
                                Nie znaleziono szukanego trenera.
                            </h1>
                        </div>
                    </div>
                )}
                {!isPageLoading && !fallBackPageView && isPageLoaded && (
                    <LayoutContainer>
                        {!selectedSlot && (
                            <>
                                <div className="content content-trainer-info">
                                    <div className="column-left">
                                        <div className="trainer-avatar-wrapper">
                                            <img 
                                                className="trainer-avatar"
                                                src={trainer.avatar}    
                                            />
                                        </div>
                                    </div>
                                    <div className="column-right">
                                        <div className="trainer-name-wrapper">
                                            <p className="trainer-name">{getFullName(trainer).label}</p>
                                        </div>
                                        <div className="trainer-bio-wrapper">
                                            <p className="trainer-bio">
                                                {trainer.bio}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="content content-product-select">
                                    <div className="option-group pack">
                                        <div className="option-group">
                                            {availableProducts.map(product => 
                                                product.isVisible && (
                                                    <div className="option-with-features" key={product.id}>
                                                        <div
                                                            className={classnames({
                                                                option: true,
                                                                active: product.id == selectedProduct.id,
                                                            })}
                                                            onClick={() => {
                                                                this.loadProduct(product.id);
                                                                this.loadLocation(product.location);
                                                                this.loadShop(product.location);
                                                            }}
                                                        >
                                                            <b>{product.name}</b> 
                                                        </div>
                                                        <div className="features">
                                                            <li className="feature">{product.feature}</li>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {!selectedSlot && (
                            <div className="content content-training-select">
                                <div className="body">
                                    <div className="training-select-container">
                                        <TrainingSelectForm
                                            location={location}
                                            history={history}
                                            leadId={trainer.id}
                                            selectedLocation={selectedLocation}
                                            selectedProduct={selectedProduct}
                                            onSubmit={this.onTrainingSelectFormSubmit}
                                            lagValue={shopPage?.lagValue}
                                            lagType={shopPage?.lagType}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {selectedSlot && (
                            <div className="content content-checkout">
                                <div className="body">
                                    <div className="checkout-container">
                                        <CheckoutForm
                                            location={location}
                                            history={history}
                                            onResetTraining={this.onResetTraining}
                                            onFormStateChange={this.onFormStateChange}
                                            data={formState}
                                            showComment={true}
                                            selectedOffer={selectedOffer}
                                            selectedLocation={selectedLocation}
                                            selectedProduct={selectedProduct}
                                            selectedSlot={selectedSlot.slot}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </LayoutContainer>
                )}
            </StyledComponent>
        );
    }
}
