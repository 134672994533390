import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        min-height: 100vh;
        position: relative;
        background-color: ${variables.dpc_backgroundWhite};

        .content {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;

            .layout-container {
                display: flex;
                justify-content: flex-end;
            }

            &.fallback {
                min-height: 70vh;
                display: flex;
                justify-content: center;

                .column {
                    .headline {
                        font-size: 3em;
                        font-weight: 300;
                        color: #565656;
                    }
                }
            }

            &.content-trainer-info {
                flex-direction: row;
                align-items: flex-start;
                margin: 4em 0;

                .column-left {
                    width: 40%;

                    .trainer-avatar-wrapper {
                        overflow: hidden;
                        border-radius: .25em;
                    }
                }

                .column-right {
                    width: 60%;
                    border-left: 0.1em solid #757575;
                    margin-left: 1em;
                    padding-left: 1em;

                    .trainer-name-wrapper {
                        .trainer-name {
                            color: ${variables.dpc_fontDark};
                            font-size: 2em;
                            font-weight: 800;
                        }
                    }

                    .trainer-bio-wrapper {
                        margin-top: .5em;

                        .trainer-bio {
                            color: ${variables.dpc_fontDark};
                            font-size: 1.25em;
                            line-height: 150%;
                            font-weight: 300;
                            white-space: pre-line;
                        }
                    }
                }
            }

            &.content-product-select {
                margin: 1em auto;
                margin-bottom: 3em;

                .option-title {
                    font-size: 1.5em;
                    font-weight: 300;
                    color: ${variables.dpc_fontDark};
                    text-align: center;
                    margin-bottom: 2.5em;
                }

                .option-group {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;    
                    width: 100%;

                    &.pack {
                        .option-group {
                            justify-content: space-evenly;
                        }
                    }
                    
                    .option-with-features {
                        min-height: 10em;
                        width: 49%;
                        
                        .features {
                            margin-top: .5em;

                            .feature {
                                position: relative;
                                display: block;
                                color: #FAFAFA;
                                font-weight: 300;
                                margin-top: .5em;
                                line-height: 1.15em;
                                padding-left: 1.5em;

                                &::before {
                                    position: absolute;
                                    content: '';
                                    display: block;
                                    width: 8px;
                                    height: 1px;
                                    background-color: #DA3143;
                                    left: .5em;
                                    top: .5em;
                                }

                                &::marker {
                                    display: none;
                                }
                            }
                        }
                    }

                    .option {
                        margin: .5em;
                        padding: 1em;
                        text-align: center;
                        font-size: 1.1em;
                        font-weight: 300;
                        color: ${variables.dpc_fontDark};
                        border: 1px solid #FC2B1B;
                        cursor: pointer;
                        min-width: 7em;

                        b {
                            display: block;
                            color: #FC2B1B;
                            font-weight: 600;
                            font-size: 2em;
                        }

                        &.active, &:hover {
                            border: none;
                            background: transparent linear-gradient(45deg, #FC2B1B 0%, #961910 100%) 0% 0% no-repeat padding-box;

                            b {
                                color: white;
                            }
                        }
                    }

                }
            }

            &.content-training-select {
                padding: 3em 0;
            }

            .body {
                text-align: left;
                width: 100%;
            }
        }

        @media (max-width: ${variables.desktopXS}) {
            .content {}
        }
        @media (max-width: ${variables.tabletL}) {
            .layout-container {
                justify-content: center;
            }

            .content {
                &.content-product-select {
                    .option-group {
                        .option-with-features {
                            width: 100%;
                        }
                    }
                }
            }
        }

        @media (max-width: ${variables.mobileL}) {
            .content {}
        }

        @media (max-width: ${variables.mobileM}) {
            .content {}
        }
    `;

